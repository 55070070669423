// generated on build time, from script >> iac/scripts/build-environment-conf.sh

export const environment = {
  production: true,
  scraperMock: true,
  env: 'dev',
  baseUrl: 'https://api.dev.opendata.bcds.net',
  notificationsBaseUrl: 'wss://notifications.dev.opendata.bcds.net'
};


